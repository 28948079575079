<template>
  <v-container fluid fill-height>
    <v-row
      align="center"
      justify="center"
      no-gutters
    >
      <v-col cols="12" xs="12" sm="8" md="4">
        <!-- copy token for localhost:8080 -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import ko from 'vee-validate/dist/locale/ko'

export default {
  data: () => ({
  }),

  mounted () {
    // 매일 해줘야 해서 귀찮지만..
    // 프론트에서 테스트하기 위해선 백엔드 3000에서 복사한것 가져와야 한다
    // localhost:8080/auth/copyToken
    const cpToken = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJnYXQiOiJ5YTI5LmEwQWZINlNNQXppVUFpWkd6WnltcTBleUZwMElqem1pc3I1OUU0b2tiNXRabzhhZm1KSU9va3lFMUhIUVZtT21kWElUZ0t5MHRMRjYtZkFKTUtXVHFZWE51dnhIbE03QlpVWDFjdklkUllTeFlPMXJUZG9NTHpwNnBQNjV2TXM4SDJSclktYW05blRmcndORHRyNk1WNDhWYnluMkJVYS12a2ZoYyIsImdleHAiOjE1OTUzOTc2OTEsImlkIjoxLCJlbWFpbCI6InJoZHVkZG5yQGdtYWlsLmNvbSIsImRiY29kZSI6IjIwMTkxMTA4MTIyNDUzMTI4NCIsImlzQWdyZWUiOiJZIiwibmFtZSI6IuqzoOyYgeyasSIsImx2IjoiTSIsImV4cERhdGUiOiIyMDIwLTA3LTIzIDIzOjU5OjU5Iiwib2ZmaWNlTmFtZSI6IuuhnOybjO2BrO2FjOyKpO2KuCIsImlhdCI6MTU5NTM5NDA5MSwiZXhwIjoxNTk1NDA0ODkxfQ.5vQn3VyEIGA4XoXjpzzEikalEWnlUB7pbeRocs1atVc`
    this.loginCopyToken(cpToken)
  },

  methods: {
    // 토큰 복사하기
    async loginCopyToken (token) {
      try {
        if (token) {
          // 토큰 저장 + 토큰을 푼 유저정보를 저장
          await this.$store.dispatch('LOGIN', { token })

          // 유저정보 패칭
          const ui = this.$store.state.ui
          // console.log('ui :', ui)

          if (ui.dbcode) {
            this.redirect('/schedule')
          } else {
            this.redirect('/')
          }
        } else {
          throw new Error('Token Not Reponsed : [401]')
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    redirect (to = '') {
      this.$router.push(to)
    }
  },
  props: {
    source: String
  }
}
</script>
